import '@babel/polyfill';
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

// eslint-disable-next-line
import Application from '../dist';

const app = Application();
const context = app.createContext({
    services: {
        mock: {
            enabled: false,
            include: [], // ['retrieveNotifications'],
            exclude: [],
        },
    },
});

const Component = app.getComponent();

ReactDOM.render(
    <AppContainer>
        <Component context={context.getComponentContext()} />
    </AppContainer>,
    document.getElementById('application'),
);

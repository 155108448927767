"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function tokenRetrieveStart(actionContext, serviceInstance) {
  actionContext.dispatch('TOKEN_RETRIEVE_START', serviceInstance.getId());
}

tokenRetrieveStart.displayName = 'tokenRetrieveStart';

function tokenRetrieveSuccess(actionContext, _ref) {
  var data = _ref.response.data;
  actionContext.dispatch('TOKEN_RETRIEVE_SUCCESS', data);
}

tokenRetrieveSuccess.displayName = 'tokenRetrieveSuccess';

function tokenRetrieveError(actionContext, serviceInstance) {
  actionContext.dispatch('TOKEN_RETRIEVE_ERROR', serviceInstance.getId());
}

tokenRetrieveError.displayName = 'tokenRetrieveError';

var _default = function _default() {
  return {
    serviceName: 'retrieveIFrameToken',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/api/v1/iframeprovider/cockpit'
    },
    //onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: tokenRetrieveStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    //afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: tokenRetrieveSuccess,
    // Handles all successful requests.
    onFailed: tokenRetrieveError,
    // Handles all 400 level responses.
    onError: tokenRetrieveError,
    // Handles all 500 level responses.
    onTimeout: tokenRetrieveError,
    // Handles a request timeout.
    onFatal: tokenRetrieveError // Handles a client exception that occurs during a service call being invoked.

  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(tokenRetrieveStart, "tokenRetrieveStart", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/iframe-token-provider-service.js");
  reactHotLoader.register(tokenRetrieveSuccess, "tokenRetrieveSuccess", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/iframe-token-provider-service.js");
  reactHotLoader.register(tokenRetrieveError, "tokenRetrieveError", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/iframe-token-provider-service.js");
  reactHotLoader.register(_default, "default", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/iframe-token-provider-service.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();
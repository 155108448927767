"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = authorize;

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function authorize(_ref) {
  var request = _ref.request,
      resolve = _ref.resolve,
      reject = _ref.reject;
  var number = request.data.number;

  if (number) {
    resolve({
      number: number
    });
  } else {
    reject(400);
  }
}

;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(authorize, "authorize", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/mock/example-service.mock.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _merge = _interopRequireDefault(require("lodash/merge"));

var _map = _interopRequireDefault(require("lodash/map"));

var _endpoints = _interopRequireDefault(require("../configs/endpoints"));

var _iframeTokenProviderService = _interopRequireDefault(require("./iframe-token-provider-service"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var DEFAULT_CONFIG = {
  endpoints: _endpoints.default
};

var _default = function _default(config) {
  var configuration = (0, _merge.default)(DEFAULT_CONFIG, config); // eslint-disable-line

  var serviceConfigs = [(0, _iframeTokenProviderService.default)(configuration)];
  var windowBaseUrl = window.BASE_URL_OVERRIDE;
  var processBaseUrl = process.env.BASE_URL_OVERRIDE;
  var baseUrlOverride = null;

  if (windowBaseUrl.toLowerCase().indexOf("http") === 0) {
    baseUrlOverride = windowBaseUrl;
  } else {
    baseUrlOverride = processBaseUrl;
  }

  if (baseUrlOverride) {
    return (0, _map.default)(serviceConfigs, function (serviceConfig) {
      return _objectSpread(_objectSpread({}, serviceConfig), {}, {
        config: _objectSpread(_objectSpread({}, serviceConfig.config), {}, {
          baseUrl: baseUrlOverride
        })
      });
    });
  }

  return serviceConfigs;
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(DEFAULT_CONFIG, "DEFAULT_CONFIG", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/index.js");
  reactHotLoader.register(_default, "default", "/builds/ENS-SmartAlerts/ens-cockpit-application/src/services/service-configs/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();